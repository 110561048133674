import { BridgeComponent } from '@hotwired/strada'

export default class extends BridgeComponent {
  static component = 'navbar'
  static targets = ['menu', 'logo', 'search', 'notification']
  static values = {
    notificationCount: String
  }

  connect() {
    super.connect()
    this.notifyBridgeOfConnect()
  }

  notifyBridgeOfConnect() {
    this.send('connect', {
      notificationCount: this.notificationCountValue
    }, (message) => {
      if (message.data.action === 'logo') {
        this.logoTarget.click()
      } else if (message.data.action === 'menu') {
        this.menuTarget.click()
      } else if (message.data.action === 'search') {
        this.searchTarget.click()
      } else if (message.data.action === 'notification') {
        this.notificationTarget.click()
      }
    })
  }
}
