import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expandable', 'expandedIndicator', 'contractedIndicator', 'clearField']
  static classes = ['active']

  connect() {
    this.storeInitialDisplays()

    const expanded = this.data.get('expanded')
    if (!expanded || expanded === 'false') {
      this.hide()
    } else {
      this.show()
    }
  }

  disconnect() {
    this.data.set('expanded', this.isExpanded())

    this.showExpandableTarget(true)
    this.showExpandedIndicator(true)
    this.showContractedIndicator(true)
  }

  storeInitialDisplays() {
    this.initialDisplays = {
      expandableTarget: this.expandableTarget.style.display
    }

    if (this.hasExpandedIndicatorTarget) {
      this.initialDisplays.expandedIndicatorTarget = this.expandedIndicatorTarget.style.display
    }

    if (this.hasContractedIndicatorTarget) {
      this.initialDisplays.contractedIndicatorTarget = this.contractedIndicatorTarget.style.display
    }
  }

  toggleExpandable(e) {
    const closestLink = e.target.closest('a')
    if (closestLink && this.element.contains(closestLink) && !closestLink.dataset.action) {
      return
    }

    e.preventDefault()
    // eslint-disable-next-line no-unused-expressions
    this.isExpanded() ? this.hide() : this.show()
  }

  isExpanded() {
    return this.expandableTarget.classList.contains(this.activeClass)
  }

  show() {
    this.showExpandableTarget(true)
    this.showExpandedIndicator(true)
    this.showContractedIndicator(false)
  }

  hide() {
    this.showExpandableTarget(false)
    this.showExpandedIndicator(false)
    this.showContractedIndicator(true)
    if (this.hasClearFieldTarget) {
      this.clearFieldTarget.value = ''
    }
  }

  showExpandableTarget(show) {
    this.expandableTarget.classList.toggle(this.activeClass, show)
  }

  showExpandedIndicator(show) {
    if (!this.hasExpandedIndicatorTarget) return

    this.showTarget('expandedIndicatorTarget', show)
  }

  showContractedIndicator(show) {
    if (!this.hasContractedIndicatorTarget) return

    this.showTarget('contractedIndicatorTarget', show)
  }

  showTarget(target, show) {
    this[target].style.display = show ? this.initialDisplays[target] : 'none'
  }
}
