import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    heartbeatInterval: Number,
    reportId: String
  }

  connect() {
    if (!this.hasReportIdValue) {
      return
    }

    window.addEventListener('beforeunload', this.updateTime.bind(this))

    this.intervalUpdate()
  }

  disconnect() {
    if (!this.hasReportIdValue) {
      return
    }

    this.updateTime()
    window.removeEventListener('beforeunload', this.updateTime.bind(this))
    clearInterval(this.intervalId)
  }

  updateTime() {
    const reportId = this.reportIdValue
    fetch('/metrics/time_update', {
      body: JSON.stringify({
        report_id: reportId
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  intervalUpdate() {
    this.intervalId = setInterval(this.updateTime.bind(this), this.heartbeatIntervalValue)
  }
}
