import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'control']

  static values = {
    select: String
  }

  updateLabel(e) {
    const labelTarget = this.labelTargets.find((label) => label.dataset.filterLabelIdParam === e.detail.id)
    labelTarget.innerText = e.detail.label

    if (e.detail.label) {
      labelTarget.parentElement.classList.remove('display-none')
    } else {
      labelTarget.parentElement.classList.add('display-none')
    }
  }

  clear({ params: { id } }) {
    const controlTarget = this.controlTargets.find((control) => control.dataset.filterControlIdValue === id)
    this.dispatch('clear', {
      target: controlTarget
    })
  }
}
