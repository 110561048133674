import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['dropdown']
  static values = {
    url: String,
    createFormId: String,
    allowCreate: {
      type: Boolean,
      default: false
    },
    valueField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'name'
    },
    searchField: {
      type: Array,
      default: ['name']
    },
    itemClass: {
      type: String,
      default: 'item'
    },
    maxOptions: {
      type: Number,
      default: 50
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, {
      ...this.baseSettings,
      ...this.apiSettings,
      ...this.additionalSettings
    })

    if (this.autofocusValue && this.#modalReady()) {
      this.focus()
    }
  }

  disconnect() {
    this.tomSelect.destroy()
  }

  async persistNewOption(value, _data) {
    if (!this.allowCreateValue || !this.hasCreateFormIdValue) {
      return
    }

    const form = document.getElementById(this.createFormIdValue)

    const formData = new FormData(form)
    // Replace the placeholder value in the form with our added value
    formData.entries().forEach((entry) => {
      if (entry[1] === '{REPLACE_ME}') {
        formData.set(entry[0], value)
      }
    })

    // Persist the new option
    const response = await fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        Accept: 'application/json'
      }
    }).then((r) => r.json())

    if (response.error !== undefined) {
      this.addError(response.error)
      return
    }

    // Reset the input with the new ID we have
    this.tomSelect.updateOption(value, { name: value, id: response.id })
    this.tomSelect.refreshItems()
  }

  addError(message) {
    const span = document.createElement('span')
    span.classList.add('form-error')
    span.textContent = message
    this.element.parentElement.appendChild(span)
  }

  async loadFunction(query, callback) {
    const url = `${this.urlValue}?query=${encodeURIComponent(query)}`
    const response = await fetch(url, this.fetchConfig)
    const json = await response.json()
    callback(json)
  }

  focus() {
    this.tomSelect.focus()
    this.tomSelect.on('focus', () => {
      setTimeout(() => { this.tomSelect.settings.openOnFocus = true }, 0)
      this.tomSelect.off('focus')
    })
  }

  get baseSettings() {
    const settings = {
      create: this.allowCreateValue,
      createFilter(input) {
        return !Object.values(this.options).find((o) => o.name === input)
      },
      onOptionAdd: this.persistNewOption.bind(this),
      plugins: ['remove_button'],
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.searchFieldValue,
      itemClass: this.itemClassValue,
      maxOptions: this.maxOptions,
      openOnFocus: !this.autofocusValue,
      onType(value) {
        // Add something that our test suite can use to know that a filter has been applied
        if (value) {
          this.dropdown.classList.add('tom-select-filtered')
        } else {
          this.dropdown.classList.remove('tom-select-filtered')
        }
      }
    }
    if (this.dropdownTarget.multiple) {
      settings.maxItems = this.dropdownTarget.dataset?.maxitems ?? null
    }
    return settings
  }

  get apiSettings() {
    if (this.hasUrlValue) {
      return { load: this.loadFunction.bind(this) }
    }
    return {}
  }

  get additionalSettings() {
    return {}
  }

  get fetchConfig() {
    return {
      headers: { Accept: 'application/json' }
    }
  }

  get maxOptions() {
    return this.maxOptionsValue === -1 ? null : this.maxOptionsValue
  }

  #modalReady() {
    const modal = document.querySelector('.modal')
    return !modal.contains(this.element) || modal.dataset.opened
  }
}
