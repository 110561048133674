import { BridgeComponent } from '@hotwired/strada'

export default class extends BridgeComponent {
  static component = 'enable-notifications'

  connect() {
    const button = this.element

    this.send('connect', {}, (message) => {
      const notificationsEnabled = message.data.granted
      button.textContent = notificationsEnabled ? 'Manage Push Notification' : 'Enable Push Notifications'
    })
  }

  openSettings(e) {
    e.preventDefault()

    this.send('openSettings')
  }
}
