import { Controller } from '@hotwired/stimulus'
import throttle from '../utilities/throttle.js'

export default class extends Controller {
  static targets = [
    'wrapper',
    'text'
  ]

  static values = {
    clampClass: { type: String, default: 'clamp' },
    clampLines: { type: Number, default: 3 },
    responsiveFont: { type: Boolean, default: false }
  }

  connect() {
    this.initialText = this.textTarget.innerText.replace(/\s+/gm, ' ').trim()

    this.clamp()
    this.resizeObserver = new ResizeObserver(throttle(this.clamp.bind(this), 100))
    this.resizeObserver.observe(this.wrapperTarget)
  }

  disconnect() {
    this.resizeObserver.disconnect()
    this.textTarget.textContent = this.initialText
  }

  async clamp() {
    if (this.clampInProgress) return

    this.clampInProgress = true

    // Prevents the browser from detecting an infinite loop
    await this.nextTick()

    this.textTarget.textContent = this.initialText
    this.textTarget.classList.remove(this.clampClassValue)

    const targetHeight = this._targetHeight()
    if (!targetHeight) {
      this.clampInProgress = false
      return
    }

    const words = this.initialText.split(' ')
    let clamped = false
    for (let i = words.length - 1; i >= 0; i -= 1) {
      if (this.wrapperTarget.clientHeight <= targetHeight) {
        break
      }

      this.textTarget.textContent = words.slice(0, i).join(' ')
      if (!clamped) {
        clamped = true
        this.textTarget.classList.add(this.clampClassValue)
      }
    }
    this.clampInProgress = false
  }

  _targetHeight() {
    if (this.targetHeight && !this.responsiveFont) return this.targetHeight

    const styles = window.getComputedStyle(this.wrapperTarget)
    if (!styles.lineHeight || !styles.fontSize) return

    const lineHeight = parseFloat(styles.lineHeight)
    this.targetHeight = Math.round(lineHeight * this.clampLinesValue)
    return this.targetHeight
  }

  nextTick() {
    return new Promise((resolve) => { setTimeout(resolve, 0) })
  }
}
