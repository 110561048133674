import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  toggle(event) {
    if (event.target === this.element) {
      this.checkboxTarget.checked = !this.checkboxTarget.checked
      this.checkboxTarget.dispatchEvent(new Event('change'))
    }
  }
}
