import { Controller } from '@hotwired/stimulus'
import { visit } from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['by']
  static values = {
    turboFrame: String,
    url: String
  }

  submit(event) {
    if (event.params.value !== undefined && event.params.value.toString() !== event.target.value) {
      return
    }

    const replace = this.byTarget.value
    const url = this.urlValue.replace('{REPLACE_ME}', replace).replace('%7BREPLACE_ME%7D', replace)

    const options = {}
    if (this.hasTurboFrameValue) {
      options.frame = this.turboFrameValue
    }

    visit(url, options)
  }
}
