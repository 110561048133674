import { Controller } from '@hotwired/stimulus'
import Panzoom from '@panzoom/panzoom'

export default class extends Controller {
  static targets = [
    'zoom'
  ]

  connect() {
    this.panzoom = Panzoom(this.zoomTarget, {
      contain: 'outside',
      overflow: 'scroll',
      panOnlyWhenZoomed: true,
      minScale: 1.0
    })
    // 300 because that's the transition time of the modal
    setTimeout(() => { this.zoomOut() }, 300)
    this.zoomTarget.addEventListener('panzoomchange', this._handleZoomChange.bind(this))
  }

  zoomIn() {
    this.panzoom.zoomIn()
  }

  zoomOut() {
    this.panzoom.zoomOut()
  }

  _handleZoomChange(event) {
    if (event.detail.scale === 1 && (event.detail.x !== 0 || event.detail.y !== 0)) {
      this.panzoom.reset()
    }
  }
}
