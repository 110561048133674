import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['scroll']
  static values = {
    throttleDelay: {
      type: Number,
      default: 15
    },
    reportId: {
      type: String,
      default: ''
    }
  }

  initialize() {
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    if (this.reportIdValue === '') {
      return
    }

    if (this.throttleDelayValue > 0) {
      this.scroll = this.throttle(this.scroll, this.throttleDelayValue)
    }

    this.scrollTarget.addEventListener('scroll', this.scroll, { passive: true })
    this.scroll()

    this.maxProgress = 0
    this.sentProgress = 0
    window.addEventListener('beforeunload', this.sendProgress.bind(this))

    this.intervalUpdate()
  }

  disconnect() {
    if (this.reportIdValue === '') {
      return
    }

    this.sendProgress()
    this.scrollTarget.removeEventListener('scroll', this.scroll)
    window.removeEventListener('beforeunload', this.sendProgress.bind(this))
    clearInterval(this.intervalId)
  }

  scroll() {
    const height = this.scrollTarget.scrollHeight - this.scrollTarget.clientHeight
    const progress = Math.floor((this.scrollTarget.scrollTop / height) * 100)
    if (progress > this.maxProgress) {
      this.maxProgress = progress
    }
  }

  sendProgress() {
    if (this.maxProgress <= this.sentProgress) {
      return
    }

    const reportId = this.reportIdValue
    fetch('/metrics/read_percent', {
      body: JSON.stringify({
        report_id: reportId,
        percent: this.maxProgress
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this.sentProgress = this.maxProgress
  }

  intervalUpdate() {
    this.intervalId = setInterval(() => {
      this.sendProgress()
    }, 1000)
  }

  throttle(callback, delay) {
    let shouldWait = false

    return (...args) => {
      if (shouldWait) return

      callback.apply(this, args)

      shouldWait = true

      setTimeout(() => {
        shouldWait = false
      }, delay)
    }
  }
}
