import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'trigger']

  connect() {
    this.modalTarget.addEventListener('transitionend', (event) => {
      if (event.target === this.modalTarget && event.propertyName === 'visibility') {
        const open = !this.modalTarget.dataset.opened
        this.modalTarget.dataset.opened = open
        this.modalTarget.dispatchEvent(new CustomEvent(open ? 'opened' : 'closed'))

        if (this.hasTriggerTarget) {
          this.triggerTarget.dispatchEvent(new CustomEvent(open ? 'opened' : 'closed'))
        }
      }
    })
  }
}
