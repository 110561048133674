import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'toggleDefault', 'toggleSelected', 'apply', 'dropdown']

  static values = {
    select: String,
    id: String
  }

  connect() {
    this.updateLabel()
  }

  closeDropdown() {
    if (!this.hasDropdownTarget) {
      return
    }

    this.dropdownTarget.hide()
  }

  select({ params: { select } }) {
    ([...this.inputTarget.options]).forEach((option) => {
      if (option.value === select) {
        if (option.selected) {
          option.selected = false
        } else {
          option.selected = true
        }
      }
    })

    this.inputTarget.dispatchEvent(new Event('change'))
  }

  chooseDate({ currentTarget }) {
    this.inputTarget.value = currentTarget.value
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  updateLabel() {
    const labels = this._selectedOptions()

    let label
    if (!labels.length) {
      label = ''
    } else if (labels.length === 1) {
      label = labels[0]
    } else {
      label = `${labels[0]} +${labels.length - 1}`
    }

    this.dispatch('update-label', {
      target: this.element,
      detail: {
        id: this.idValue,
        label
      }
    })
  }

  clear(event) {
    event.preventDefault()

    if (this.inputTarget.type === 'text') {
      this.inputTarget.value = ''
    } else {
      [...this.inputTarget.selectedOptions].forEach((option) => {
        option.selected = false
      })
    }

    this.inputTarget.dispatchEvent(new Event('change'))
    this.updateLabel()

    if (this.hasApplyTarget) {
      this.applyTarget.click()
    }
  }

  _selectedOptions() {
    if (this.inputTarget.type === 'text') {
      return [this.inputTarget.value].filter((v) => !!v)
    }

    return [...this.inputTarget.selectedOptions].map((option) => option.text)
  }
}
