import { Controller } from '@hotwired/stimulus'
import { useMutation, useThrottle, useWindowResize } from 'stimulus-use'

export default class extends Controller {
  static throttles = ['setPlaceholderHeight']
  static targets = [
    'banner',
    'placeholder'
  ]

  connect() {
    useThrottle(this, { wait: 250 })
    useWindowResize(this)
    useMutation(this, { attributes: true, childList: true, subtree: true })
    this.setPlaceholderHeight()
  }

  mutate() {
    this.setPlaceholderHeight()
  }

  windowResize() {
    this.setPlaceholderHeight()
  }

  setPlaceholderHeight() {
    this.placeholderTarget.style.height = `${this.bannerTarget.offsetHeight}px`
  }
}
