export function getCssValueFromVariable(name) {
  return getComputedStyle(document.documentElement).getPropertyValue(name)
}

export function pickGradientColor(color1, color2, position) {
  const inversePosition = 1 - position

  return {
    r: Math.round(color1.r * position + color2.r * inversePosition),
    g: Math.round(color1.g * position + color2.g * inversePosition),
    b: Math.round(color1.b * position + color2.b * inversePosition)
  }
}

export function onColorThemeChange(callback) {
  const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)')
  colorSchemeQueryList.addEventListener('change', callback)
}
