import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import { color } from 'chart.js/helpers'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'
import { getCssValueFromVariable, onColorThemeChange, pickGradientColor } from '../utilities/color.js'

Chart.register(MatrixController, MatrixElement)

export default class extends Controller {
  static values = {
    data: Array,
    lowColor: String,
    highColor: String,
    padding: { type: Number, default: 3 }
  }

  connect() {
    this._setColors()
    onColorThemeChange(this._setColors.bind(this))

    const columns = [...new Set(this.dataValue.map((item) => item.x))].length
    const maxValue = Math.max(...this.dataValue.map((item) => item.value))

    const data = {
      datasets: [{
        data: this.dataValue,
        backgroundColor: (c) => {
          const value = c.dataset.data[c.dataIndex].value
          const alpha = Math.max((value / maxValue) || 0, 0.03)
          const newColor = pickGradientColor(this.highColor, this.lowColor, alpha)
          return color(newColor).rgbString()
        },
        width: (c) => {
          const a = c.chart.chartArea || {}
          return (a.right - a.left) / columns - this.paddingValue
        },
        height: (c) => {
          const a = c.chart.chartArea || {}
          return (a.bottom - a.top) / this.labels.length - this.paddingValue
        }
      }]
    }

    this.chart = new Chart(this.element.getContext('2d'), {
      type: 'matrix',
      data,
      options: this.options
    })
  }

  get labels() {
    return [...new Set(this.dataValue.map((item) => item.y))]
  }

  get options() {
    return {
      aspectRatio: 4,
      plugins: {
        legend: false,
        tooltip: {
          displayColors: false,
          callbacks: {
            title: () => '',
            label: (context) => context.dataset.data[context.dataIndex].label
          }
        }
      },
      scales: this.scales
    }
  }

  get scales() {
    return {
      y: {
        type: 'category',
        labels: this.labels,
        offset: true,
        reverse: true,
        position: 'left',
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          font: {
            size: 9
          }
        },
        grid: {
          display: false,
          drawBorder: false,
          tickLength: 0
        }
      },
      x: {
        type: 'time',
        position: 'top',
        offset: true,
        time: {
          unit: 'day',
          round: 'day',
          isoWeekday: 1,
          displayFormats: {
            day: 'EEEEE'
          }
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          font: {
            size: 9
          }
        },
        grid: {
          display: false,
          drawBorder: false,
          tickLength: 0
        }
      }
    }
  }

  _setColors() {
    this.lowColor = color(getCssValueFromVariable(this.lowColorValue)).rgb
    this.highColor = color(getCssValueFromVariable(this.highColorValue)).rgb

    if (this.chart) {
      this.chart.update('color-scheme')
    }
  }
}
