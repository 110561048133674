import { BridgeComponent } from '@hotwired/strada'

export default class extends BridgeComponent {
  static component = 'notification-token'

  connect() {
    this.send('connect', {}, (message) => {
      const token = message.data.token

      fetch('/api/v1/notification_tokens', {
        body: JSON.stringify({ token }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  }
}
