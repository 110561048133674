import FlatpickrController from 'stimulus-flatpickr'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import throttle from '../utilities/throttle.js'

export default class extends FlatpickrController {
  initialize() {
    this.originalShowMonths = Number(this.data.get('showMonths') || '1')
    this.minScreenWidth = 650
    this._resizeFunc = throttle(this._handleResponsive.bind(this), 500)

    this.config = {
      nextArrow: this._icon('east'),
      prevArrow: this._icon('west'),
      closeOnSelect: false,
      locale: {
        weekdays: {
          shorthand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        }
      },
      plugins: [
        ShortcutButtonsPlugin({
          button: [
            { label: 'Cancel', attributes: { class: 'btn' } },
            { label: 'Done', attributes: { class: 'btn-primary margin-left-xs' } }
          ],
          onClick(index, flatpickr) {
            if (index === 0) {
              flatpickr.clear()
            }
            flatpickr.close()
          }
        })
      ]
    }
  }

  connect() {
    super.connect()

    this._handleResponsive()
    window.addEventListener('resize', throttle(this._resizeFunc))
  }

  disconnect() {
    super.disconnect()
    window.removeEventListener('resize', this._resizeFunc)
  }

  ready(_selectedDates, _dateStr, fp) {
    const date = new Date()
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    // Set current month on the right pane so we aren't showing disabled months
    if (fp.config.showMonths > 1 && fp.config.maxDate && fp.config.maxDate <= endOfMonth) {
      fp.changeMonth(-1)
    }
  }

  clear(event) {
    event.stopPropagation()

    this.fp.clear()
    this.fp.close()
  }

  _icon(name) {
    return `<button class="btn btn--icon btn--small"><span class="material-symbols-outlined">${name}</span></button>`
  }

  _handleResponsive() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (screenWidth < this.minScreenWidth && this.config.showMonths === this.originalShowMonths) {
      this._updateShowMonths(1)
    } else if (screenWidth >= this.minScreenWidth && this.config.showMonths !== this.originalShowMonths) {
      this._updateShowMonths(this.originalShowMonths)
    }
  }

  _updateShowMonths(showMonths) {
    this.fp.destroy()
    this.data.set('showMonths', showMonths)
    super.connect()
  }
}
