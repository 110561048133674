import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown']
  static values = {
    url: String,
    default: String
  }

  async update(e) {
    const urlReplaceValue = e.target.value
    let data = {}
    if (urlReplaceValue === '') {
      data = JSON.parse(this.defaultValue)
    } else {
      data = await fetch(this.urlValue.replace('{REPLACE_ME}', urlReplaceValue)).then((r) => r.json())
    }
    this.updateDropdown(data)
  }

  updateDropdown(data) {
    for (let i = this.dropdownTarget.options.length - 1; i >= 0; i -= 1) {
      if (this.dropdownTarget[i].text !== '') {
        this.dropdownTarget.remove(i)
      }
    }

    Object.entries(data).forEach(([text, value]) => {
      const option = document.createElement('option')
      option.value = value
      option.text = text
      this.dropdownTarget.add(option)
    })
  }
}
