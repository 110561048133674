import { Controller } from '@hotwired/stimulus'
import { getCssValueFromVariable, onColorThemeChange } from '../utilities/color.js'

export default class extends Controller {
  connect() {
    this._updateAllCharts()

    // When we get here, the charts are likely uninitialized; so set a proxy that will call our function when they are
    // Added to the charts object
    window.Chartkick.charts = new Proxy(window.Chartkick.charts, {
      set: (target, key, chart) => {
        this._updateColors(chart)
        target[key] = chart
        return true
      }
    })

    onColorThemeChange(this._updateAllCharts.bind(this))
  }

  _updateAllCharts() {
    window.Chartkick.eachChart((chart) => {
      this._updateColors(chart)
    })
  }

  _watchThemeChanges() {
    const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)')
    colorSchemeQueryList.addEventListener('change', this._updateAllCharts.bind(this))
  }

  _updateColors(chart) {
    let originalColors = chart.options.originalColors
    if (!originalColors) {
      // Persist the original colors so that we can re-use them on theme changes
      originalColors = chart.options.colors
      chart.options.originalColors = originalColors
    }
    if (!originalColors) {
      return
    }

    chart.options.colors = []
    originalColors.forEach((color) => {
      if (color.startsWith('--')) {
        chart.options.colors.push(getCssValueFromVariable(color))
      } else {
        chart.options.colors.push(color)
      }
    })

    chart.redraw()
  }
}
