import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'columns',
    'fullRow'
  ]

  connect() {
    this.makeRowFullSpan()
  }

  makeRowFullSpan() {
    if (this.hasFullRowTarget) {
      this.fullRowTarget.colSpan = this.columnsTarget.children.length
    }
  }
}
