import { Controller } from '@hotwired/stimulus'
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs'

export default class extends Controller {
  static classes = ['hide']
  static targets = ['canvas', 'currentPage', 'pageCount', 'prevBtn', 'nextBtn', 'error']

  static values = {
    url: String,
    viewportScale: { type: Number, default: 1.0 }
  }

  async connect() {
    try {
      this.pdf = await pdfjsLib.getDocument(this.urlValue).promise
      this.page = 1

      this._updateControls()
      this.queuePage(1)
    } catch (e) {
      console.error('Error fetching PDF', e)
      if (this.hasErrorTarget) {
        this.canvasTarget.classList.add(this.hideClass)
        this.errorTarget.classList.remove(this.hideClass)
      }
    }
  }

  next() {
    this.queuePage(this.page + 1)
  }

  previous() {
    this.queuePage(this.page - 1)
  }

  queuePage(page) {
    if (this.queue === undefined) {
      this.queue = this._renderPage(page)
    } else {
      this.queue.then(() => this._renderPage(page))
    }
  }

  async _renderPage(page) {
    if (!this._canNavigate(page)) {
      return
    }

    this.page = page
    const pdfPage = await this.pdf.getPage(page)

    const viewport = pdfPage.getViewport({ scale: this.viewportScaleValue })
    this.canvasTarget.width = viewport.width
    this.canvasTarget.height = viewport.height

    await pdfPage.render({
      canvasContext: this.canvasTarget.getContext('2d'),
      viewport
    }).promise

    this._updateControls()
  }

  _canNavigate(page) {
    return page >= 1 && page <= this.pdf.numPages
  }

  _updateControls() {
    if (this.hasCurrentPageTarget) {
      this.currentPageTarget.textContent = this.page
    }
    if (this.hasPageCountTarget) {
      this.pageCountTarget.textContent = this.pdf.numPages
    }
    if (this.hasNextBtnTarget) {
      this._setDisabled(this.nextBtnTarget, !this._canNavigate(this.page + 1))
    }
    if (this.hasPrevBtnTarget) {
      this._setDisabled(this.prevBtnTarget, !this._canNavigate(this.page - 1))
    }
  }

  _setDisabled(target, disabled) {
    if (disabled) {
      target.setAttribute('disabled', 'disabled')
    } else {
      target.removeAttribute('disabled')
    }
  }
}
