import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="report"
export default class extends Controller {
  static targets = [
    'dataSheet',
    'reportDate',
    'product',
    'reportType',
    'analyst',
    'primaryCompany',
    'bond',
    'alternateCompanies',
    'title',
    'override',
    'titleOverride',
    'headlineOverride',
    'capLine',
    'body',
    'summary',
    'buySell',
    'category'
  ]

  static values = {
    type: String
  }

  EXCLUDE_FIELDS = {
    'Bottom Ten Report': [
      'dataSheet',
      'buySell',
      'primaryCompany',
      'bond',
      'capLine',
      'category'
    ],
    'Daily Report': [],
    'Industry Report': [
      'buySell',
      'primaryCompany',
      'bond',
      'capLine',
      'category'
    ],
    'Special Report': [
      'dataSheet',
      'buySell',
      'primaryCompany',
      'bond',
      'override',
      'capLine',
      'category'
    ],
    'Top Ten Report': [
      'dataSheet',
      'buySell',
      'primaryCompany',
      'bond',
      'capLine',
      'category'
    ]
  }

  connect() {
    const reportType = (this.typeValue) ? this.typeValue : this.getDropdownValue(this.reportTypeTarget)
    this.selectFields(reportType || 'Daily Report')
  }

  changeType(e) {
    this.selectFields(this.getDropdownValue(e.target))
  }

  getDropdownValue(target) {
    return target.options[target.selectedIndex].text
  }

  selectFields(type) {
    const excluded = this.EXCLUDE_FIELDS[type]

    // eslint-disable-next-line no-restricted-syntax
    for (const target of this.constructor.targets) {
      if (excluded.includes(target)) {
        this.clearInput(this[`${target}Target`])
        this[`${target}Target`].classList.add('display-none')
      } else {
        this[`${target}Target`].classList.remove('display-none')
      }
    }
  }

  clearInput(wrapper) {
    wrapper.querySelectorAll('select, input').forEach((input) => {
      input.value = null
    })
    wrapper.querySelectorAll('.tom_select .item a').forEach((item) => {
      item.click()
    })
  }
}
