import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: { type: String, default: '' }
  }

  print() {
    window.print()
  }

  open_url_and_print() {
    const win = window.open(this.urlValue, '_blank')

    win.onload = function () {
      win.print()

      // This won't work if content-type is anything other than HTML
      win.onafterprint = function () {
        win.close()
      }
    }
  }
}
