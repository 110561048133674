import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  setHeight() {
    this.element.height = `${this.height() + 4}px`
  }

  height() {
    const contentWindow = this.element.contentWindow
    const element = contentWindow.document.querySelector('html')

    let height = element.offsetHeight
    height += parseInt(contentWindow.getComputedStyle(element).getPropertyValue('margin-top'), 10)
    height += parseInt(contentWindow.getComputedStyle(element).getPropertyValue('margin-bottom'), 10)

    return height
  }
}
