function throttle(mainFunction, delay) {
  let scheduled = false
  let rerun = false

  return (...args) => {
    if (scheduled === false) {
      scheduled = true

      mainFunction(...args)

      setTimeout(() => {
        if (rerun) {
          mainFunction(...args)
        }

        scheduled = false
        rerun = false
      }, delay)
    } else {
      rerun = true
    }
  }
}

export default throttle
