import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'focus',
    'form',
    'formMain',
    'menuItems',
    'navItems',
    'closeSearch'
  ]

  connect() {
    if (new URLSearchParams(window.location.search).has('query')) {
      this.openSearch()
    }
  }

  openSearch() {
    this.menuItemsTargets.forEach((target) => target.classList.toggle('display-none')) // toggle search and menu button
    this.navItemsTargets.forEach((target) => target.classList.toggle('search-visible'))
    this.closeSearchTarget.classList.toggle('display-none') // toggle close button
    this.formMainTarget.classList.toggle('form-visible') // hide the input and button
    this.focusTarget.focus()
  }

  cancelSearch() {
    this.menuItemsTargets.forEach((target) => target.classList.toggle('display-none'))
    this.navItemsTargets.forEach((target) => target.classList.toggle('search-visible'))
    this.closeSearchTarget.classList.toggle('display-none') // toggle close button
    this.formMainTarget.classList.toggle('form-visible') // show the input and button
  }

  openMobileSearch() {
    this.menuItemsTargets.forEach((target) => target.classList.toggle('display-none')) // toggle search and menu button
    this.closeSearchTarget.classList.toggle('display-none') // toggle close button
    document.getElementById('logo').classList.toggle('display-none') // hide logo
    this.formTarget.classList.toggle('form-visible') // hide the input and button
    this.focusTarget.focus()
  }

  cancelMobileSearch() {
    this.menuItemsTargets.forEach((target) => target.classList.toggle('display-none'))
    this.closeSearchTarget.classList.toggle('display-none') // toggle close button
    document.getElementById('logo').classList.toggle('display-none') // show logo
    this.formTarget.classList.toggle('form-visible') // show the input and button
  }
}
