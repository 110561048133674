import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['menu']

  connect() {
    useClickOutside(this)
  }

  close() {
    this.menuTarget.removeAttribute('open')
  }
}
