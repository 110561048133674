import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['scrollParent', 'turboFrame']

  connect() {
    window.addEventListener('turbo:before-stream-render', (event) => {
      if (event.target.target === this.turboFrameTarget.id) {
        const currentScrollBottomPosition = this.scrollParentTarget.scrollHeight - this.scrollParentTarget.scrollTop

        const originalRender = event.detail.render

        // Persist scroll relative to the scroll bottom position so that we have a smooth experience when adding to the
        // top of the list
        event.detail.render = async (newElement) => {
          await originalRender(newElement)

          this.scrollParentTarget.scroll(0, this.scrollParentTarget.scrollHeight - currentScrollBottomPosition)
        }
      }
    })
  }
}
