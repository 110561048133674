import { Controller } from '@hotwired/stimulus'
import { visit } from '@hotwired/turbo'

export default class extends Controller {
  updatePerPage(e) {
    const query = new URLSearchParams(window.location.search)
    query.set('per_page', e.target.value)
    visit(`${window.location.pathname}?${query.toString()}`)

    // TODO: The below commented will return a turbo stream but won't update the history :/
    /*
    Turbo.visit(
      `${window.location.pathname}?${query.toString()}`,
      { action: 'advance', acceptsStreamResponse: true }
    )
    */
  }
}
